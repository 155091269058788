import React from 'react'

import BlockContent from '@sanity/block-content-to-react'

import { Serializer } from 'src/utils/serializer'
import { Image } from 'src/components/image'

export interface CTABlockProps {
  data: {
    text: any[]
    image: {
      imageId: string
    }
  }
}

export const CTABlock = ({ data }: CTABlockProps) => {
  const {
    text,
    image
  } = data
  return (
    <div className='x pr z1 cta__block bg--off-white df bt jcc aic'>
      <div className='tc p5 p8--800 pr z2 color--white'>
        <div className='pt6--800 pb6--800'>
          <BlockContent blocks={text} serializers={Serializer} />
        </div>
      </div>
      <div className='pa top left z1 cta__block-image x y'>
        <Image className='x pa top left z1 y' imageId={image.imageId}  />
      </div>
    </div>
  )
}

export default CTABlock